<template>
  <v-container v-if="dataVar">
    <div>
      <v-dialog v-model="dialog" eager width="500" persistent>
        <v-btn color="error" block @click="paymentCancel">
          <v-icon left>{{ icons.mdiClose }}</v-icon>
          KAPAT
        </v-btn>
        <div id="credit"></div>
      </v-dialog>

      <v-row >
        <v-col class="d-block d-md-flex justify-center pt-2 pb-6 py-md-16">
          <v-card
          elevation="16"
          :rounded="$vuetify.breakpoint.mdAndUp ? 'xl' : 'lg'"        
          >
            <v-card-title class="grey" >
              <v-row>
                <v-col cols="12" md="3">
            <div class="illustrator-img">
            <v-img
              class="white--text align-end"
              v-if="linkData.dosya_adi"    
              contain
              height="80"
              :src="linkData.dosya_adi"             
            ></v-img>
            </div>
          </v-col>   
          <v-col cols="12" md="7">
            <div class="text-h6  align-center justify-center" v-html="linkData.stext" /> 
          </v-col>
          <v-col cols="12" md="2">
              <v-spacer />
              <v-chip class="mr-3 " small label> ID: {{ linkData.id }} </v-chip>
              <v-chip :color="linkData.installment_active == '1' ? 'success' : 'warning'" link outlined label small>
                {{ linkData.installment_active == '1' ? 'Aktif' : 'Pasif' }}
              </v-chip>
            </v-col>
          </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-list color="transparent">
                <v-list-item two-line class="py-2">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6 center">
                      <div class="text-h6  align-center justify-center" v-html="linkData.content" /> 
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  class="my-3"
                  style="margin-left: -20px; max-width: calc(100% + 40px) !important; width: calc(100% + 40px) !important"
                />           
                <v-btn color="error" x-large depressed block class="mt-6 white--text" @click="addPayment">
                  Ödeme Yap :               
                    {{ linkData.price | currency }}                
                </v-btn>
                <v-divider
                class="my-3"
                style="margin-left: -20px; max-width: calc(100% + 40px) !important; width: calc(100% + 40px) !important"
              />     
                            
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container class="pa-6" v-if="OdemeYapilsin===true">            
                  <v-row>
                    <v-col cols="12" md="5"></v-col>
                    <v-col cols="12" md="7">
                      <v-text-field
                        v-model="manuelTutar"
                        hide-details
                        label="Ödenecek Tutar"
                        filled
                        rounded
                        class="rounded"
                        suffix="TL"
                      ></v-text-field>
                    </v-col>
                  </v-row>  
                  <div v-if="paymentError" class="px-3 pt-3">
                    <v-alert prominent type="error">
                      <div>Ödeme Başarısız</div>
                      <div class="text-caption">
                        {{ paymentErrorMsg }}
                      </div>
                    </v-alert>
                  </div>     
                  <div>
                    <CardForm 
                      :form-data="formData"
                      @input-card-number="updateCardNumber"
                      @input-card-name="updateCardName"
                      @input-card-month="updateCardMonth"
                      @input-card-year="updateCardYear"
                      @input-card-cvv="updateCardCvv"
                    />
                  </div>
                  <v-row v-if="taksitlendirme.installment">
                    <v-col cols="12">
                      <v-item-group v-model="taksitSecim" mandatory>
                        <v-row dense>
                          <v-col v-for="taksit in taksitlendirme.installment" :key="taksit.id" cols="6" md="2">
                            <v-item v-slot="{ active, toggle }">
                              <v-card
                                :color="active ? 'secondary' : 'nightDark'"
                                class="d-flex align-center justify-center"
                                dark
                                height="75"
                                @click="toggle"
                              >
                                <div class="text-body-1" :class="active ? 'font-weight-medium white--text' : 'black--text'">
                                  <span v-if="taksit.installment_text === 'Tek Çekim'" class="text-center">
                                    {{ taksit.installment_text }}
                                  </span>
                                  <span v-else> {{ taksit.installment_text.charAt(0) }} Taksit </span>
                                </div>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-item-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="5"></v-col>
                    <v-col cols="12" md="7">
                      <v-card class="rounded-lg" flat color="nightDark">
                        <div class="d-flex align-center justify-space-between">
                          <v-card-title>
                            <div class="text-caption text-md-body-1 font-weight-medium">Ödenecek Tutar</div>
                            <div v-if="taksitSayisi != 'T' && taksitSayisi != 0" class="text-caption">
                              ( {{ taksitTutar | currency }} x {{ taksitSayisi }} )
                            </div>
                          </v-card-title>

                          <v-card-title class="justify-end">
                            <v-chip color="secondary" label :large="$vuetify.breakpoint.mdAndUp">
                              {{ toplamOdenecekTutar | currency }}
                            </v-chip>
                          </v-card-title>
                        </div>
                      </v-card>
                      <v-btn color="error" x-large depressed block class="mt-6 white--text" @click="submit">
                        Ödeme Yap
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container> 
              </v-form>
            
            
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  </div>
  </v-container>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import { mdiAccountCircle, mdiAt, mdiCalendar, mdiLock, mdiPhone, mdiShieldAccount, mdiUpdate } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'
import PaymentForm from '@/views/payment/Payment.vue'
import CardForm from '@/components/CardForm.vue'
import orderStoreModule from '@/views/order/orderStoreModule'
const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'
export default {
  components: {
    PaymentForm ,
    CardForm
  },
    
  setup() {
    
    //const { uid } = JSON.parse(localStorage.getItem('userData'))

    const kredikartino = ref('')
    const kredikartiadsoyad = ref('')
    const kredikartiinstallment = ref([])
    const kredikartiay = ref('')
    const kredikartiyil = ref('')
    const kredikarticvv = ref('')

    const valid = ref(true)
    const kkAdSoyad = ref(null)
    const kkAy = ref(null)
    const kkYil = ref(null)
    const kkCvv = ref(null)
    const kkNo = ref(null)
    const selectedInstallment = ref(null)
    const bankaKodu = ref(null)
    const cardFamily = ref(null)
    const cardBankName = ref(null)

    const dialog = ref(false)
    const form = ref(null)
    const paymentErrorMsg = ref(null)
    const paymentError = ref(false)
    const loading = ref(true)

    const { router } = useRouter()   
    const linkData = ref({})   
    const pageLoading = ref(true) 
    const OdemeYapilsin = ref(false)
    const toplamOdenecekTutar = ref(0)
    const taksitlendirme = ref({})
    const taksitSayisi = ref(0)
    const taksitSecim = ref(null)
    const taksitTutar = ref(0)
    const dataVar = ref(false) 



      // Register module
      if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
    }

    onMounted(() => {
      debugger
      const method = 'getPaymentLinkDetail'
      let Id 
      if (router.currentRoute.params.id) {
        Id = router.currentRoute.params.id
      } else {
        Id = '784eeefb'
      }
              
      const query = {
      paymentId: Id     
      }        

      store
      .dispatch('postMethod', {
              method: 'getPaymentLinkDetail',
              paymentId: Id,
            })
        .then(response => {
          debugger
          if (response.error == 0) {
            dataVar.value=true  
            linkData.value = response.detail
            pageLoading.value = false
          
          }else {
            dataVar.value=false  
            linkData.value ={}   
            Vue.swal({
              title: 'Hata',
              html: response.msg,
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })    
          }
          
        })
        .catch(error => {
          if (error.response.status == 404) {
            linkData.value = {}
            dataVar.value=false  
          }
        })
    })
    const addPayment = () => {
      debugger
      OdemeYapilsin.value=true
    }
    
    const submit = async () => {
      debugger
      
      const payload = {
        method: 'addPayment',
        tutar: toplamOdenecekTutar.value,
        paymentType: 1,
        kk_no: kredikartino.value.replaceAll(' ', '') || '4506347048543223',
        kk_expire: kredikartiay.value + '/' + kredikartiyil.value.slice(-2) || '12/30',
        kk_adsoyad: kredikartiadsoyad.value || 'mehmet tali',
        kk_cvv: kredikarticvv.value || '000',
        selectedInstallment: kredikartiinstallment.value?.[taksitSecim.value]?.id || '130',
        bankaKodu: bankaKodu.value || '0015',
        cardFamily: cardFamily.value || 'World',
        cardBankName: cardBankName.value || 'Vakıfbank',
        paymentMethod: 2,
        paymentDiffMode: 1,
        cardType: 1,
        calcedPrice: '',
        paycode: router.currentRoute.params.id
      }
      //console.log(kredikartiinstallment.value?.[taksitSecim.value]?.id)

      if (!form.value.validate()) {
        return
      }
      paymentErrorMsg.value = ''
      paymentError.value = false

      loading.value = true

      debugger
      postData(payload).then(async response => {
        if (response.error === false) {
          dialog.value = true
          iframe = document.createElement('iframe')
          iframe.width = '500'
          iframe.height = '500'
          iframe.srcdoc = response.html

          document.getElementById('credit').appendChild(iframe)
       
        } else {
          paymentErrorMsg.value = response.msg
          paymentError.value = true
          Vue.swal({
            title: 'Hata',
            html: response.msg,
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })

    } 

    const paymentCancel = () => {
      orderStatus.value = null
      paymentErrorMsg.value = null
      paymentError.value = false
      loading.value = false
      iframe.remove()
      whileStop.value = true
      dialog.value = false
    }

    return {
      paymentErrorMsg,
      paymentError,
      paymentCancel,
      linkData,    
      loading,
      pageLoading,     
      submit,
      addPayment,
      OdemeYapilsin,    
      taksitlendirme,
      toplamOdenecekTutar ,
      taksitSayisi,
      taksitSecim,
      taksitTutar,
      dataVar,

      form,
      dialog,
      valid,
      kkAdSoyad,
      kkAy,
      kkYil,
      kkCvv,
      kkNo,
      kredikartino,
      kredikartiadsoyad,
      kredikartiay,
      kredikartiyil,
      kredikarticvv,
      kredikartiinstallment,
      selectedInstallment,
      bankaKodu,
      cardFamily,
      cardBankName,

      icons: {
        mdiShieldAccount,
        mdiAccountCircle,
        mdiLock,
        mdiPhone,
        mdiAt,
        mdiCalendar,
        mdiUpdate,
      },
    }
  },

  data() {
    return {      
      manuelTutar:'',
      formData: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      },
    }
  },

  watch: {
    manuelTutar(val) {
      this.toplamOdenecekTutar = val
    },
    taksitSecim(val) {
      debugger
      this.updateCardNumber(this.kredikartino ,val)

      /* this.toplamOdenecekTutar = this.taksitlendirme.installment[val].inst_tot_amount
      this.taksitTutar = this.taksitlendirme.installment[val].inst_amount
      this.taksitSayisi = this.taksitlendirme.installment[val].installment_text.charAt(0) */
    },
  },
 
  methods: {
    updateCardNumber(val,taksit_sayisi=0) {
      if (val.length > 18) {
        debugger
        const payload = {
          method: 'getInstallmentsByBin',
          kk_no: val.replaceAll(' ', ''),
          price: this.toplamOdenecekTutar,
        }
        this.$store.dispatch('app-order/fetchBinList', payload).then(response => {
          debugger
          this.taksitlendirme = response
          this.cardBankName = response.card_bank_name
          this.cardFamily = response.card_family
          this.bankaKodu = response.bank_code
          this.kredikartiinstallment = response.installment

          //gmz: tutar değiştiğinde eski tutardan hesaplıyordu
          this.toplamOdenecekTutar = this.taksitlendirme.installment[taksit_sayisi].inst_tot_amount     
          this.taksitTutar = this.taksitlendirme.installment[taksit_sayisi].inst_amount
          this.taksitSayisi = this.taksitlendirme.installment[taksit_sayisi].installment_text.charAt(0)
        })

        this.kkNo = val
        this.kredikartino = val
      } else {
        this.taksitlendirme = {}
      }
    },
    updateCardName(val) {
      this.kkAdSoyad = val
      this.kredikartiadsoyad = val
    },
    updateCardMonth(val) {
      this.kkAy = val
      this.kredikartiay = val
    },
    updateCardYear(val) {
      this.kkYil = val
      this.kredikartiyil = val
    },
    updateCardCvv(val) {
      this.kkCvv = val
      this.kredikarticvv = val
    },
  },
}
</script>
